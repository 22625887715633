import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import pluralize from '@mc/fn/pluralize';

import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import FeedbackBlock from '../FeedbackBlock';
import List, { ListItem } from '../List';
import { useAnnouncer } from '../LiveRegion';
import FormContext from './FormContext';

import stylesheet from './FormFeedback.css';

import { TranslateForm } from './TranslateForm';

const pluralizeErrors = (count: number) => {
  // Translate default text
  const { feedbackErrorText, feedbackErrorsText } = TranslateForm(count);
  return pluralize(feedbackErrorText, feedbackErrorsText, count);
};

export type FormFeedbackProps = {
  /** It is recommended to include the number of errors in the message to help users
   * understand the error's scope. The title prop accepts a function to customize the message.
   * The error count is passed as the first argument to be used with your updated message. */
  title: React.ReactNode | ((errorCount: number) => React.ReactNode);
};

const FormFeedback = ({ title = pluralizeErrors }: FormFeedbackProps) => {
  const { errors, isValid, hasSubmitted } = useContext(FormContext);
  const isActive = hasSubmitted && !isValid;
  const errorCount = Object.keys(errors).length;
  // Option to pass title as a function to create custom copy using the count variable.
  const _title = typeof title === 'function' ? title(errorCount) : title;
  // Screen reader to alert loading indicator
  const announce = useAnnouncer('assertive');

  useEffect(() => {
    if (isActive) {
      announce(_title);
    }
  }, [_title, announce, isActive]);

  return isActive ? (
    <FeedbackBlock
      variant="error"
      title={_title}
      className={cx({
        'wink-visually-hidden': !isActive,
      })}
      inline
    >
      <List appearance="small" className={stylesheet.list}>
        {Object.keys(errors).map((key) => {
          return mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
            <ListItem appearance="small" key={key}>
              {errors[key]}
            </ListItem>
          ) : (
            <ListItem key={key}>{errors[key]}</ListItem>
          );
        })}
      </List>
    </FeedbackBlock>
  ) : null;
};

export default FormFeedback;
